a:hover,
a:focus {
    text-decoration: none !important;
    color: dodgerblue;
}
.euiHeaderSectionItem:after {
    top: 0;
}
.euiHeaderLogo img{
    width: auto !important;
}
.euiHeaderLogo img.euiIcon--large{
    height: 48px;
}

th span{
    width: 100%;
    text-align: center;
}
#custom-report tr > th , #custom-report tr > td{
    /*border: 1px solid #ccc !important;*/
}
.styledDatePicker{
    padding-top: 2px;
    padding-bottom: 2px;
    width:75%
}
.react-datepicker__header__dropdown > div{
    display: flex;
}
.react-datepicker__header__dropdown > div > div{
    padding:0px 5px;
}